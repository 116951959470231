<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, index) in propostas"
        :key="item"
        cols="auto"
        class="ml-2 cursor-pointer"
        @click="selectTable(index)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="45"
              :variant="`light-${resolveInvoiceStatusVariantAndIcon(item.title).variant}`"
            >
              <feather-icon
                :icon="resolveInvoiceStatusVariantAndIcon(item.title).icon"
              />
            </b-avatar>
          </template>
          <b-media-body>
            <h4 class="font-weight-bolder mb-0">
              {{ item.items.length }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ item.title }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-col
              lg="8"
              class="d-flex justify-content-end"
              style="margin-bottom: 15px;"
            >
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Pesquisar"
                debounce="800"
                size="sm"
              />
            </b-col>
          <b-table
            :fields="fields"
            :items="propostas[table].items"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            striped
            hover
            samall
            responsive
            @filtered="onFiltered(propostas[table].items)"
          >
            <template #cell(numero_proposta)="data">
              <b-link
                class="font-weight-bold"
              >
                #{{ data.item.numero_proposta }}
              </b-link>
            </template>

            <template #cell(status)="data">
              <b-row>
                <b-col>
                  <b-avatar
                    :id="`invoice-row-${data.item.id}`"
                    size="32"
                    :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
                  >
                    <feather-icon
                      :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
                    />
                  </b-avatar>
                  <b-tooltip
                    :target="`invoice-row-${data.item.id}`"
                    placement="top"
                  >
                    <p class="mb-0">
                      {{ data.item.status }}
                    </p>
                  </b-tooltip>
                </b-col>
                <b-col>
                  <b-avatar
                    :id="`vizualizar-proposta-${data.item.id}`"
                    class="mr-1 cursor-pointer"
                    size="32"
                    :variant="`light-warning`"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      @click="ShowProposta(data.item)"
                    />
                    <b-tooltip
                      :target="`vizualizar-proposta-${data.item.id}`"
                      placement="top-right"
                    >
                      <p class="mb-0">
                        Vizualizar proposta
                      </p>
                    </b-tooltip>
                  </b-avatar>
                </b-col>
                <b-col>
                  <b-avatar
                    class="mr-1 cursor-pointer"
                    size="32"
                    :variant="`light-primary`"
                  >
                    <feather-icon
                      icon="ListIcon"
                      @click="ShowLogProposta(data.item.id)"
                    />
                    <b-tooltip
                      title="Visualizar Log Proposta"
                      placement="top-right"
                    >
                      <p class="mb-0">
                        Visualizar Log Proposta
                      </p>
                    </b-tooltip>
                  </b-avatar>
                </b-col>
              </b-row>
            </template>

            <template #cell(cliente)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="getInitials(data.item.cliente.nome)"
                    :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.cliente.nome }}
                </span>
              </b-media>
            </template>

            <template #cell(data_proposta)="data">
              {{ dataHora(data.item.created_at) }}
            </template>

            <template #cell(total)="data">
              {{ data.item.valor_nominal ? valorBr(parseFloat(data.item.valor_nominal), true) : '' }}
            </template>

            <template #cell(nome_cliente)="data">
              {{ getNomeCliente(data.item.proposta) }}
            </template>

            <template #cell(nome_credor)="data">
              {{ getNomeCredor(data.item.proposta) }}
            </template>

            <template #cell(actions)="data">
              <b-row>
                <b-col
                  class="mr-1"
                  md="3"
                >
                  <b-button
                    v-if="data.item.status === 'Analise Supervisao' || data.item.status === 'Analise Credor'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    @click="aprovarProposta(data.item)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                    />
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-button
                    v-if="data.item.status === 'Analise Supervisao' || data.item.status === 'Analise Credor'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="reprovarProposta(data.item)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              align="center"
            />
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="showProposta"
      size="xl"
      no-close-on-backdrop
    >
      <ViewProposta />

      <hr>

      <b-row>
        <b-col>
          <b-row>
            <b-col class="text-center">
              <h4>{{ progressMessage }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress
                :key="bar.variant"
                animated
                :value="bar.value"
                :variant="bar.variant"
                :class="'progress-bar-' + bar.variant"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="mr-1">
          <b-button
            variant="outline-warning"
            size="md"
            class="float-right"
            @click="enviarParaInstituicao"
          >
            Enviar para Instituição
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="viewLogProposta"
      title="Log Proposta"
      ok-only
    >
      <LogProposta
        :id-proposta="idProposta"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BTooltip,
  BLink,
  BButton,
  BModal,
  BProgress,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import api from '@/../axios-auth'
import firebase from '@/../firebase'

import ViewProposta from '../../negociacao/tabs/Proposta/components/ViewProposta.vue'
import LogProposta from './cards/LogProposta.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BTooltip,
    BLink,
    BButton,
    ViewProposta,
    BModal,
    BProgress,
    LogProposta,
    BFormInput,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    updateContador: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: 'numero_proposta', label: 'Número Proposta' },
        { key: 'data_proposta', label: 'Data Proposta' },
        { key: 'nome_cliente', label: 'Cliente' },
        { key: 'nome_credor', label: 'Credor' },
        { key: 'total', label: 'Total', class: 'text-right' },
        { key: 'status', label: 'Status', class: 'text-center' },
      ],
      items: [],
      propostasAnalise: [],
      propostasAprovadas: [],
      propostasRecusadas: [],

      propostaSelected: null,

      bar: { variant: '', value: 0 },
      progressMessage: '',

      propostas: [
        {
          title: 'Todos',
          items: [],
        },
        {
          title: 'Analise Supervisao',
          items: [],
        },
        {
          title: 'Aprovado',
          items: [],
        },
        {
          title: 'Reprovado',
          items: [],
        },
        {
          title: 'Analise Credor',
          items: [],
        },
      ],

      table: 0,
      idProposta: null,
    }
  },
  computed: {
    ...mapState({
      credor: state => state.proposta.credor,
      cliente: state => state.proposta.cliente,
    }),
  },
  created() {
    this.getInvoices()
  },
  methods: {
    getNomeCliente(data) {
      const json = JSON.parse(data)
      let result = ''

      if (json && json.cliente && json.cliente.nome) {
        result = json.cliente.nome
      }

      return result
    },
    getNomeCredor(data) {
      const json = JSON.parse(data)
      return json !== null ? json.credor.nome : ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    selectTable(value) {
      this.table = value
      if (value === 1 || value === 4) {
        this.fields = [
          { key: 'numero_proposta', label: 'Número Proposta' },
          { key: 'data_proposta', label: 'Data Proposta' },
          { key: 'nome_cliente', label: 'Cliente' },
          { key: 'nome_credor', label: 'Credor' },
          { key: 'total', label: 'Total', class: 'text-right' },
          { key: 'status', label: 'Status', class: 'text-center' },
          { key: 'actions', label: 'Ações' },
        ]
      } else {
        this.fields = [
          { key: 'numero_proposta', label: 'Número Proposta' },
          { key: 'data_proposta', label: 'Data Proposta' },
          { key: 'nome_cliente', label: 'Cliente' },
          { key: 'nome_credor', label: 'Credor' },
          { key: 'total', label: 'Total', class: 'text-right' },
          { key: 'status', label: 'Status', class: 'text-center' },
        ]
      }
      this.totalRows = this.propostas[value].items.length
    },
    getInvoices() {
      api.get('api/v1/proposta/listar').then(res => {
        const { dados } = res.data
        // this.items = dados
        this.propostas = [
          {
            title: 'Todos',
            items: [],
          },
          {
            title: 'Analise Supervisao',
            items: [],
          },
          {
            title: 'Aprovado',
            items: [],
          },
          {
            title: 'Reprovado',
            items: [],
          },
          {
            title: 'Analise Credor',
            items: [],
          },
        ]
        dados.map(dt => {
          this.propostas[0].items.unshift(dt)

          if (dt.status === 'Analise Supervisao') {
            this.propostas[1].items.unshift(dt)
          } else if (dt.status === 'Aprovado') {
            this.propostas[2].items.unshift(dt)
          } else if (dt.status === 'Reprovado') {
            this.propostas[3].items.unshift(dt)
          } else if (dt.status === 'Analise Credor') {
            this.propostas[4].items.unshift(dt)
          }
        })
        this.totalRows = dados.length
      })
    },

    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 'Analise Supervisao') return { variant: 'warning', icon: 'PieChartIcon' }
      if (status === 'Aprovado') return { variant: 'success', icon: 'CheckCircleIcon' }
      if (status === 'Reprovado') return { variant: 'danger', icon: 'InfoIcon' }
      if (status === 'Analise Credor') return { variant: 'info', icon: 'InfoIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    },

    resolveClientAvatarVariant(status) {
      if (status === 'Aprovado') return 'danger'
      if (status === 'Reprovado') return 'primary'
      if (status === 'Analise Supervisao') return 'warning'
      if (status === 'Analise Credor') return 'warning'
      return 'primary'
    },

    aprovarProposta(item) {
      this.$swal({
        title: 'Deseja aprovar a proposta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, aprovar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          api.put(`api/v1/proposta/update/status/${item.id}`, {
            status: 'Aprovado',
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
              Accept: 'application/json',
            },
          }).then(async () => {
            await this.getInvoices()
            await this.updateContador()
            await firebase.ref(`proposta/${item.numero_proposta}`).update({
              status: true,
            })
            this.$swal({
              icon: 'success',
              text: 'Proposta aprovada',
              timer: 1500,
              showConfirmButton: false,
            })
          })
        }
      })
    },

    reprovarProposta(item) {
      this.$swal({
        title: 'Deseja reprovar a proposta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, reprovar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          api.put(`api/v1/proposta/update/status/${item.id}`, {
            status: 'Reprovado',
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
              Accept: 'application/json',
            },
          }).then(async () => {
            await this.getInvoices()
            await this.updateContador()
            this.$swal({
              icon: 'success',
              text: 'Proposta reprovada',
              timer: 1500,
              showConfirmButton: false,
            })
          })
        }
      })
    },

    ShowProposta(item) {
      if (item.proposta) {
        const proposta = JSON.parse(item.proposta)

        Object.keys(proposta).map(key => {
          this.$store.commit('proposta/setProposta', {
            key,
            value: proposta[key],
          })
        })
        this.propostaSelected = item
        this.$refs.showProposta.toggle()
      } else {
        // mensagem erro
      }
    },
    async enviarParaInstituicao() {
      this.$swal({
        text: 'Deseja gerar Proposta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, gerar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const bodyDemonstrativo = JSON.parse(this.propostaSelected.proposta)
          const { cliente } = this
          //  const { cliente } = bodyDemonstrativo

          bodyDemonstrativo.simulacao = true
          bodyDemonstrativo.acordo.status = 'Aguardando'
          bodyDemonstrativo.acordo.cliente_id = cliente.id

          this.progressMessage = 'Gerando PDF...'
          this.bar = {
            value: 25,
            variant: 'success',
          }
          const demonstrativo = await api
            .post('api/v1/acordo/viewDemoSimples', bodyDemonstrativo, {
              responseType: 'arraybuffer',
            })
            .then(res => {
              const file = new File([res.data], 'demonstrativo.pdf', {
                type: 'application/pdf',
              })
              return file
            })

          this.progressMessage = 'Enviando para credor...'
          this.bar.value = 75

          const { nome } = cliente

          const representantes = await api.get(`api/v1/credor_contatos/${this.credor.id}`).then(res => res.data.dados)

          const to = representantes.filter(e => e.relatorios.filter(i => i.title === 'Proposta').length > 0).map(item => ({
            name: item.nome,
            address: item.email,
          }))

          const getRemuneracao = parseFloat(bodyDemonstrativo.acordo.honorarios_porcento) === 0 && parseFloat(bodyDemonstrativo.acordo.comissao_porcento) > 0

          const body = new FormData()
          body.append('name', `${nome}`)
          body.append('from', JSON.stringify({ name: localStorage.userName, address: localStorage.userEmail }))
          body.append('to', JSON.stringify(to))
          body.append('credor', this.credor.nome)
          body.append('demonstrativo', demonstrativo)

          body.append(
            'dataProposta',
            this.americaDate(bodyDemonstrativo.parcelas[0].data_vencimento),
          )
          body.append('total', `R$ ${this.valorBr(parseFloat(bodyDemonstrativo.acordo.valor_nominal), false)}`)
          body.append('nParcelas', bodyDemonstrativo.parcelas.length)
          body.append('getRemuneracao', getRemuneracao)
          // eslint-disable-next-line camelcase
          body.append('cpfCliente', this.cliente.cpf_cnpj)
          body.append(
            'table',
            `
            <table class="MsoNormalTable" border="1" cellspacing="0" cellpadding="0">
                        <tbody>
                        <tr>
                          <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Juros</span></b></p>
                          </td>
                          <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Multa</span></b></p>
                          </td>
                          <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Honorário</span></b></p>
                          </td>
                          <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Desconto</span></b></p>
                          </td>
                        </tr>
                        <tr style="height:7.1pt">
                          <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.juros_porcento
  // eslint-disable-next-line indent
                            }%</span></b></p>
                          </td>
                          <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.multas_porcento
}%</span></b></p>
                          </td>
                          <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.honorarios_porcento
}%</span></b></p>
                          </td>
                          <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
    <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.desconto_porcento
}%</span></b></p>
  </td>
                        </tr>
                        <tr>
                          <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.juros),
    true,
  )}</span></p>
                          </td>
                          <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.multas),
    true,
  )}</span></p>
</td>
                          <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.honorarios),
    true,
  )}</span></p>
                          </td>
                          <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                            <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.desconto),
    true,
  )}</span></p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
          `,
          )

          const configEmail = {
            method: 'post',
            url: 'http://192.168.254.219:3000/api/v1/proposta-credor',
            data: body,
          }

          await api(configEmail)

          const bodyEvento = {
            descricao: '<h2>Proposta Enviada para o credor<h2>',
            cliente_id: this.cliente.id,
            motivo_evento_id: 43,
          }

          this.progressMessage = 'Gerando Evento'
          this.bar.value = 75

          await api.post('api/v1/eventos/store', bodyEvento)

          await api.put(`api/v1/proposta/update/status/${this.propostaSelected.id}`, {
            status: 'Analise Credor',
          })

          this.progressMessage = 'Concluído...'
          this.bar.value = 100

          setTimeout(() => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Concluído',
                  icon: 'CheckIcon',
                  text: 'Proposta enviada para o credor',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.progressMessage = ''
            this.bar = {
              value: 0,
              variant: '',
            }
          }, 1000)
        }
      })
    },
    ShowLogProposta(id) {
      this.idProposta = id
      this.$refs.viewLogProposta.show()
    },
  },
}
</script>
