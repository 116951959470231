<template>
  <div>
    <b-tabs justified>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Acordo</span>
        </template>
        <b-row class="mb-1">
          <b-col>
            <label><strong>Nº Acordo</strong></label>
            <b-form-input
              v-model="acordo.numero_acordo"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Status</strong></label>
            <b-form-input
              v-model="acordo.status"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Valor Nominal</strong></label>
            <b-form-input
              v-model="acordo.valor_nominal"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Valor Entrada</strong></label>
            <b-form-input
              v-model="acordo.valor_entrada"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Qntº Parcelas</strong></label>
            <b-form-input
              v-model="acordo.quantidade_parcelas"
              disabled
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col>
            <label><strong>Valor da Parcela</strong></label>
            <b-form-input
              v-model="acordo.valor_parcela"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>{{ acordo.juros_porcento }}% Juros</strong></label>
            <b-form-input
              v-model="acordo.juros"
              disabled
            />
          </b-col>

          <b-col>
            <label for=""><strong>{{ acordo.multas_porcento }}% Multas</strong></label>
            <b-form-input
              v-model="acordo.multas"
              disabled
            />
          </b-col>

          <b-col>
            <label for=""><strong>{{ acordo.honorarios_porcento }}% Hon. Variável</strong></label>
            <b-form-input
              v-model="acordo.honorarios"
              disabled
            />
          </b-col>
        </b-row>
        <b-row
          v-if="!cancelar"
          class="mb-1"
        >
          <b-col>
            <label for=""><strong>Data Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.data_cancelou"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Desc. Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.descricao_cancelamento"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Usuário Cancelou</strong></label>
            <b-form-input
              :value="acordo.usuario_cancelou_id ? acordo.usuario_cancelou_id.name : ''"
              disabled
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col md="6">
            <label><strong>Executivo</strong></label>
            <b-row>
              <b-col md="8">
                <v-select
                  v-model="executivoCobranca"
                  label="nome"
                  :options="executivos"
                  :disabled="!editExecutiv"
                />
              </b-col>
              <b-button
                v-if="!editExecutiv"
                variant="primary"
                @click="editExecutiv = true"
              >
                Trocar Executivo
              </b-button>
              <div v-else>
                <b-button
                  class="mr-1"
                  variant="success"
                  @click="TrocarExecutivo"
                >
                  Salvar
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="editExecutiv = false"
                >
                  Cancelar
                </b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Operações</span>
        </template>
        <b-table
          bordered
          striped
          hover
          :fields="fieldsOperacoes"
          :items="itemsOperacoes"
        >
          <template #cell(valor_nominal)="data">
            {{ valorBr(data.item.valor_nominal, true) }}
          </template>
        </b-table>
      </b-tab>

      <b-tab v-if="cancelar">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Parcelas</span>
        </template>
        <b-table
          responsive
          striped
          :fields="fieldsParcelas"
          :items="itemsParcelas"
        >
          <template #cell(data_vencimento)="data">
            {{ americaDate(data.item.data_vencimento) }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              v-if="data.item.forma_pagamento !== 'cartao_credito'"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <div v-if="data.item.forma_pagamento !== 'cartao_credito'">
                <b-dropdown-item
                  v-if="verifyVencimentoParcela(data.item.data_vencimento) && (data.item.valor_baixado === 'PENDENTE')"
                  @click="atualizarParcela(data.item)"
                >
                  <feather-icon icon="UploadCloudIcon" />
                  <span class="align-middle ml-50">Atualizar Parcela</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!verifyVencimentoParcela(data.item.data_vencimento)"
                  @click="reenvioBoleto(data.item)"
                >
                  <feather-icon icon="SendIcon" />
                  <span class="align-middle ml-50">Reenviar Boleto</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!verifyVencimentoParcela(data.item.data_vencimento)"
                  @click="sendSms(data.item)"
                >
                  <feather-icon icon="MailIcon" />
                  <span class="align-middle ml-50">Reenviar SMS</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!verifyVencimentoParcela(data.item.data_vencimento) && data.item.forma_pagamento === 'boleto'"
                  @click="visualizarBoleto(data.item)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                  />
                  <span class="align-middle ml-50">Visualizar Boleto</span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>
        </b-table>
      </b-tab>

      <!-- <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Parcelas</span>
        </template>
      </b-tab> -->
    </b-tabs>
    <b-modal
      ref="vencParcelaUpdate"
      title="Vencimento"
      ok-only
      ok-title="Atualizar Vencimento"
      @ok="handleOk"
    >
      <b-form-input
        v-model="updateParcela.data_vencimento"
        locale="pt"
        type="date"
        class="my-1"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTabs,
  BTab,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTabs,
    BTab,
    BTable,
    BButton,
    vSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    cancelar: {
      type: Boolean,
      required: false,
      default: () => {},
    },
    updateTable: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      editExecutiv: false,
      executivoCobranca: {
        nome: '',
        id: '',
      },
      acordo: null,
      operacoes: null,
      fieldsOperacoes: [
        'aluno',
        { key: 'numero_operacao', label: 'nº operação' },
        { key: 'valor_nominal', label: 'vl. nomimal' },
      ],
      itemsOperacoes: [],
      fieldsParcelas: [
        { key: 'numero_parcela', label: 'Parcela', class: 'text-center' },
        { key: 'forma_pagamento', label: 'F. Pagamento', class: 'text-center' },
        { key: 'valor_baixado', label: 'Vl. Baixado', class: 'text-center' },
        { key: 'valor_nominal', label: 'Nominal', class: 'text-center' },
        { key: 'valor_juros', label: 'Juros', class: 'text-center' },
        { key: 'valor_multa', label: 'Multa', class: 'text-center' },
        { key: 'valor_honorario', label: 'Hon. Variável', class: 'text-center' },
        { key: 'data_vencimento', label: 'Data Vencimento', class: 'text-center' },
        { key: 'actions', label: 'Ações', class: 'text-center' },
      ],
      itemsParcelas: [],
      updateParcela: {},
      idParcela: 0,
    }
  },
  created() {
    this.getExecutivos()
    this.acordo = this.info
    this.executivoCobranca = this.info.executivo_cobranca_id
    this.info.operacoes_acordo.map(dt => {
      this.itemsOperacoes.unshift(dt.operacao)
    })
    this.info.parcelas.map(dt => {
      this.itemsParcelas.push(dt)
    })
  },
  methods: {
    handleOk() {
      api.put(`api/v1/parcelas/update/${this.idParcela}`, this.updateParcela, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.updateTable()
        this.$swall({
          title: 'Vencimento Atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
    },
    atualizarParcela(data) {
      this.idParcela = data.id
      this.updateParcela = {
        valor_nominal: parseFloat(data.valor_nominal.replace(/[^0-9]/g, '')) / 100,
        data_vencimento: data.data_vencimento,
        numero_parcela: data.numero_parcela,
        data_baixa: data.data_baxa,
        valor_baixado: data.valor_baixado === 'PENDENTE' ? null : null,
        valor_juros: parseFloat(data.valor_juros.replace(/[^0-9]/g, '')) / 100,
        valor_multa: parseFloat(data.valor_multa.replace(/[^0-9]/g, '')) / 100,
        valor_honorario: parseFloat(data.valor_honorario.replace(/[^0-9]/g, '')) / 100,
        forma_pagamento: data.forma_pagamento,
        acordos_id: data.acordos_id,
        usuario_empresas_id: data.usuario_empresas_id,
      }
      this.$refs.vencParcelaUpdate.show()
    },
    async visualizarBoleto(item) {
      await api.get(`api/v1/boletos/visualizar/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          accept: 'application/json',
        },
      }).then(async res => {
        const boletoBody = {
          cod_banco: res.data.dados.codigo_banco,
          convenio: res.data.dados.convenio,
          linha_digitavel: res.data.dados.linha_digitavel,
          codigo_barra: res.data.dados.codigo_barra,
          chave_pix: res.data.dados.chave_pix,
          nome_beneficiario: res.data.dados.nome_beneficiario,
          cnpj_beneficiario: '000.000/0000-00',
          endereco_beneficiario: res.data.dados.endereco_beneficiario,
          data_vencimento: this.americaDate(res.data.dados.vencimento),
          valor: this.valorBr(res.data.dados.valor),
          nosso_numero: res.data.dados.nosso_numero,
          carteira: res.data.dados.carteira,
          agencia: res.data.dados.agencia,
          data_documento: this.americaDate(res.data.dados.vencimento),
          numero_documento: res.data.dados.numero_documento,
          desconto: res.data.dados.desconto,
          deducoes: res.data.dados.deducoes,
          juros: res.data.dados.juros,
          acrescimos: res.data.dados.acrescimos,
          valor_cobrado: this.valorBr(res.data.dados.valor),
          nome_pagador: res.data.dados.nome_pagador,
          cpf_pagador: res.data.dados.cpf_pagador,
          nome_avaliador: res.data.dados.nome_avaliador,
          codigo_credor: res.data.dados.codigo_credor,
          parcelas_id: item.id,
          id_pix: res.data.dados.id_pix,
          usuario_empresas_id: localStorage.usuarioEmpresas,
        }

        await api.post('api/v1/boletos/viewBoleto', boletoBody, {
          headers: {
            Accept: 'application/pdf',
            Authorization: 'Bearer '.concat(localStorage.token),
          },
          responseType: 'arraybuffer',
        }).then(reponse => {
          const file = new File([reponse.data], 'boleto.pdf', {
            type: 'application/pdf',
          })
          const url = URL.createObjectURL(file)
          window.open(url)
        })
      })
    },

    async reenvioBoleto(item) {
      await api.get(`api/v1/boletos/visualizar/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          accept: 'application/json',
        },
      }).then(res => {
        const { boleto } = res.data.dados
        if (boleto) {
          // eslint-disable-next-line no-unused-vars
          const boletoBody = {
            cod_banco: boleto.cod_banco,
            convenio: boleto.convenio,
            linha_digitavel: boleto.linha_digitavel,
            codigo_barra: boleto.codigo_barra,
            chave_pix: boleto.chave_pix,
            nome_beneficiario: boleto.nome_beneficiario,
            cnpj_beneficiario: '000.000/0000-00',
            endereco_beneficiario: boleto.endereco_beneficiario,
            data_vencimento: this.americaDate(boleto.vencimento),
            valor: this.valorBr(boleto.valor),
            nosso_numero: boleto.nosso_numero,
            carteira: boleto.carteira,
            agencia: boleto.agencia,
            data_documento: this.americaDate(boleto.vencimento),
            numero_documento: boleto.numero_documento,
            desconto: boleto.desconto,
            deducoes: boleto.deducoes,
            juros: boleto.juros,
            acrescimos: boleto.acrescimos,
            valor_cobrado: this.valorBr(boleto.valor),
            nome_pagador: boleto.nome_pagador,
            cpf_pagador: boleto.cpf_pagador,
            nome_avaliador: boleto.nome_avaliador,
            codigo_credor: boleto.codigo_credor,
            parcelas_id: item.id,
            id_pix: boleto.id_pix,
            usuario_empresas_id: localStorage.usuarioEmpresas,
          }
          api.post('api/v1/boletos/viewBoleto', boletoBody, {
            headers: {
              Accept: 'application/pdf',
              Authorization: 'Bearer '.concat(localStorage.token),
            },
            responseType: 'arraybuffer',
          }).then(response => {
            const file = new File([response.data], 'boleto.pdf', {
              type: 'application/pdf',
            })
            const body = new FormData()
            body.append('name', `${this.fisrtLastName(localStorage.userName)}`)
            body.append('devedor', `${this.valorBr(parseFloat(item.valor_nominal), true)}`)
            body.append('file', file)
            body.append('to', 'suporte@grupovoz.com.br')
            body.append('reason', 'reenvio_boleto')
            body.append('subject', 'Boleto')

            const configEmail = {
              method: 'post',
              url: 'http://192.168.254.216:3333/negociacao/boleto',
              data: body,
            }
            api(configEmail).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificação',
                  icon: 'BellIcon',
                  text: 'Email Reenviado!',
                  variant: 'success',
                },
              }, {
                position: 'top-center',
                timeout: 1500,
              })
            })
          })
        }
      })
    },

    async sendSms(item) {
      if (item.boleto) {
        const cell = '11982403231'
        this.$swal({
          text: `Desejar enviar sms para ${cell}?`,
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: 'Sim, enviar!',
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const body = {
              celular: cell,
              mensagem: `Segue seu boleto para quitação de débitos ${item.boleto.linha_digitavel}`,
            }
            api.post('api/v1/sms/enviar', body, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.token),
                Accept: 'application/json',
              },
            }).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificação',
                  icon: 'Operação executada',
                  text: 'SMS Enviado!',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
            })
          }
        })
      }
    },

    verifyVencimentoParcela(item) {
      if (item) {
        const splitDate = item.split('-')
        if (splitDate.length === 3) {
          // 2021-11-09
          const currentDate = new Date()
          const dataVencimento = new Date(`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
          return (dataVencimento.getDate() - currentDate.getDate()) < 0
        }
      }
      return false
    },
    getExecutivos() {
      api.get('api/v1/executivo', {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(res => {
        const executivos = []
        res.data.map(executivo => {
          executivos.push(executivo)
        })
        this.executivos = executivos
      })
    },
    async TrocarExecutivo() {
      const { executivoCobranca, acordo } = this
      api.post(`api/v1/executivo/trocarExecutivo/${acordo.id}`, {
        executivo_cobranca_id: executivoCobranca.id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Alterado executivo do acordo!',
            variant: 'success',
          },
        }, {
          position: 'top-center',
        })
        this.editExecutiv = false
        this.updateTable()
      })
    },
  },
}
</script>
