<template>
  <div>
    <b-col>
      <b-card hidden>
        <DashMigracao />
      </b-card>
    </b-col>
    <b-card
      v-if="items"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Estatísticas</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <!-- Ultima atualização: {{ hoje }} -->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(item, i) in items"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            @click="itemClick(i)"
          >
            <b-media
              no-body
              class="cursor-pointer"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                  <b-overlay
                    :show="item.load"
                    class="md-1"
                    spinner-variant="dark"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      ref="leaderboard"
      title="Ranking"
      hide-footer
      size="lg"
    >
      <div>
        <b-card
          v-for="(item, i) in leaderboard"
          :key="i"
        >
          <b-row align-h="between">
            <b-col
              class="d-flex"
            >
              # {{ i + 1 }}
            </b-col>
            <b-col class="d-flex">
              <span>
                <strong>
                  {{ item.nome }}
                </strong>
              </span>
            </b-col>
            <b-col class="justify-content-end d-flex">
              {{ valorBr(item.valor_total, true) }}
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'
import DashMigracao from './DashMigracao.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BModal,
    BOverlay,
    DashMigracao,
  },
  props: {
    getPendencias: {
      type: Function,
      required: false,
      default: () => {},
    },
    selectCard: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      countAnexos: [],
      countClientDataStatus: [],
      countTrocas: [],
      items: [
        {
          load: true,
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: '',
          subtitle: 'Acordos no mês',
          title: '',
        },
        {
          load: true,
          color: 'light-info',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'UsersIcon',
          subtitle: 'Propostas de Negociação',
          title: '',
        },
        {
          load: true,
          color: 'light-success',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'BellIcon',
          subtitle: 'Pendêcias',
          title: '',
        },
        {
          color: 'light-warning',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'AwardIcon',
          subtitle: 'Ranking de Executivos',
          title: '',
        },
        {
          color: 'light-success',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'BriefcaseIcon',
          subtitle: 'Carteira',
          title: '',
        },
        {
          load: true,
          color: 'light-danger',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'UserCheckIcon',
          subtitle: 'Classificação cliente',
          title: '',
        },
        {
          load: true,
          color: 'light-secondary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'PaperclipIcon',
          subtitle: 'Anexos',
          title: '',
        },
        {
          load: true,
          color: 'light-warning',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'RefreshCcwIcon',
          subtitle: 'Troca Executivos',
          title: '',
        },
        {
          load: true,
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'ClipboardIcon',
          subtitle: 'Baixas Administrativas',
          title: '',
        },
        {
          load: true,
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'PhoneCallIcon',
          subtitle: 'Solicitações de Ligações',
          title: '',
        },
      ],
      leaderboard: [],
    }
  },
  created() {
    this.getRanking()
    this.getClassificationClient()
    this.getAnexos()
    this.getTrocas()
  },
  methods: {
    updateContadores(value) {
      this.items[2].load = false
      this.items[2].title = value
    },
    updatePropostas(value) {
      this.items[1].load = false
      this.items[1].title = value
    },
    updateAcordos(value) {
      this.items[0].load = false
      this.items[0].title = value
    },
    clientsStatusLength(value) {
      this.items[5].load = false
      this.items[5].title = value
    },
    anexoslength(value) {
      this.items[6].load = false
      this.items[6].title = value
    },
    updateTrocas(value) {
      this.items[7].load = false
      this.items[7].title = value
    },
    updateBaixas(value) {
      this.items[8].load = false
      this.items[8].title = value
    },
    updateSolicitacoes(value) {
      this.items[9].load = false
      this.items[9].title = value
    },

    async itemClick(data) {
      if (data === 0) {
        await this.selectCard(0)
      } else if (data === 1) {
        await this.selectCard(1)
      } else if (data === 2) {
        await this.selectCard(2)
        await this.getPendencias()
      } else if (data === 3) {
        this.$refs.leaderboard.show()
      } else if (data === 4) {
        await this.selectCard(4)
      } else if (data === 5) {
        await this.selectCard(5)
        await this.getClassificationClient()
      } else if (data === 6) {
        await this.selectCard(6)
      } else if (data === 7) {
        await this.selectCard(7)
      } else if (data === 8) {
        await this.selectCard(8)
      } else if (data === 9) {
        await this.selectCard(9)
      }
    },

    async getRanking() {
      const data = new Date()
      let dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()
      this.hoje = `${dia}/${mes}/${ano}`

      if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
        dia = '31'
      } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
        dia = '30'
      } else {
        dia = '28'
      }

      const formData = new FormData()
      formData.append('data_inicial', `${ano}-${mes}-01`)
      formData.append('data_final', `${ano}-${mes}-${dia}`)

      this.leaderboard = []

      await axios.post('api/v1/executivo/rank', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(async res => {
          this.leaderboard = res.data.dados
        })
    },

    // Só serve pra contar os clientes e seus status.
    async getClassificationClient() {
      await axios.get('api/v1/relatorios/backoffice/listar/statusClientes', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(res => {
        this.countClientDataStatus = res.data.dados.length
        this.clientsStatusLength(this.countClientDataStatus)
      })
    },

    async getAnexos() {
      await axios.get('api/v1/anexos/listar').then(res => {
        this.countAnexos = res.data.length
        this.anexoslength(this.countAnexos)
      })
    },
    async getTrocas() {
      axios.get('api/v1/troca_executivo/listar', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(res => {
          const itemsTrocas = []
          res.data.dados.map(item => {
            if (item.status === 'Analise') {
              itemsTrocas.push(item)
            }
          })
          this.countTrocas = itemsTrocas.length
          this.updateTrocas(this.countTrocas)
        })
    },
  },
}
</script>
