<template>
  <div v-f="permissions">
    <div v-if="permissions['acordo.index'].permissao">
      <b-row class="mb-1">
        <b-col>
          <b-button
            v-b-modal.filtro
            class="bg-info bg-darken-2"
          >
            Filtro
          </b-button>
        </b-col>
      </b-row>

      <AcordosList
        v-if="loading"
        :acordos="acordos"
        :update-table="setFilter"
      />
      <!--Modal para filtros-->
      <b-modal
        id="filtro"
        ok-title="Filtrar"
        ok-only
        @ok="setFilter"
      >
        <b-row
          align-h="around"
          class="mb-2 mt-2"
        >
          <b-col cols="start">
            <b-row align-h="center">
              <h4>Inicio</h4>
            </b-row>
            <b-row>
              <b-form-input
                v-model="vencimento.inicio"
                locale="pt"
                type="date"
              />
            </b-row>
          </b-col>
          <b-col cols="end">
            <b-row align-h="center">
              <h4>Fim</h4>
            </b-row>
            <b-row>
              <b-form-input
                v-model="vencimento.final"
                locale="pt"
                type="date"
              />
            </b-row>
          </b-col>

        </b-row>
        <!-- select credores-->
        <b-row
          align-h="around"
          class="mb-2 mt-2"
        >
          <b-col
            cols="start"
            md="10"
          >
            <span
              cols="start"
            >
              Credores
            </span>
            <v-select
              v-model="credoresList.nome"
              label="title"
              :options="credoresList.options"
              multiple
            />
          </b-col>
        </b-row>
        <!-- select credores-->

      </b-modal>
      <!--Modal para filtros-->
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BModal,
  BFormInput,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from '@/../axios-auth'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import AcordosList from './AcordosList.vue'

export default {
  components: {
    vSelect,
    BModal,
    BCol,
    BRow,
    BButton,
    BFormInput,
    AcordosList,
    notAuthorized,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      credoresList: {
        options: [],
        id: [],
        nome: [],
      },
      permissions: null,
      hoje: '',
      vencimento: {
        inicio: '',
        final: '',
      },
      data: [],

      filter: '',
      acordos: [],
      loading: false,
    }
  },

  computed: {
    optionsCredor() {
      const options = []

      this.credoresList.map(item => {
        options.push(item.nome)
      })
      return options.sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).acordo

    axios.get('api/v1/credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      const listData = res.data.dados
      listData.map(credor => {
        this.credoresList.options.push({ title: credor.nome, id: credor.id })
      })
    })
  },
  mounted() {
    // this.$bvModal.show('filtro')
    this.initFilter()
  },

  methods: {
    async initFilter() {
      const data = new Date()
      let dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()
      this.hoje = `${dia}/${mes}/${ano}`

      if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
        dia = '31'
      } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
        dia = '30'
      } else {
        dia = '28'
      }
      this.vencimento.inicio = `${ano}-${mes}-01`
      this.vencimento.final = `${ano}-${mes}-${dia}`

      await this.setFilter()
    },
    async setAcordos(data) {
      this.loading = false
      await (this.acordos = data)
      this.loading = true
    },
    async setFilter() {
      this.loading = false
      const filtro = {}

      if (this.vencimento.inicio) {
        filtro.data_inicial = this.vencimento.inicio.concat(' 00:00:00')
      }
      if (this.vencimento.final) {
        filtro.data_final = this.vencimento.final.concat(' 23:59:59')
      }

      if (this.credoresList.nome.length > 0) {
        this.credoresList.id = []
        await this.credoresList.nome.map(item => {
          this.credoresList.id.push(item.id)
        })
        filtro.credores = this.credoresList.id
      }

      if (Object.keys(filtro).length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } else {
        // BUSCANDO ACORDOS PELA API
        await axios.post('api/v1/acordo/filtroAcordo', { filtros: filtro }, {
          headers: {
            Accept: 'Application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(res => {
          this.data = res.data.dados.filtro
          this.$bvModal.hide('filtro')
          this.loading = false
          this.setAcordos(this.data)
          this.loading = true
        })
      }
    },
  },
}
</script>
